require('../scss/index.scss');

// gsap
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// scrollmagic
// import ScrollMagic from "scrollmagic";

// smooth scrollbar
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';

(function () { $(function () {

    // bypass auto scrolling.
    if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
    }

    // ============================================================================
    // ========================= SCROLL ANIMATIONS ================================
    // ============================================================================

    // ------------------------------------------------------------
    // RESPONSIVE
    // - calculate basic screen/resonsive variables to use in your code
    // ------------------------------------------------------------

    var isLaptop, isTablet, isMobile, isMobileSmall;

    function calcScreen() {
        let screenWidth = window.innerWidth;

        if (screenWidth <= 1200) isLaptop = true; else isLaptop = false; 
        if (screenWidth <= 990) isTablet = true; else isTablet = false; 
        if (screenWidth <= 767) isMobile = true; else isMobile = false; 
        if (screenWidth <= 375) isMobileSmall = true; else isMobileSmall = false;   
    }

    calcScreen();

    // ______________________________
    // smooth scroll
    // ''''''''''''''''''''''''''''''

    // Responsive plugin

    let isTouchDevice = (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    // isTouchDevice = true;
    
    let bodyScrollBar;

    if (!isTouchDevice) {

        // Setup
        const body_scroller_element = document.getElementById('body-scroller');

        
        bodyScrollBar = Scrollbar.init(body_scroller_element, {
            damping: 0.1, 
            delegateTo: document, 
            alwaysShowTracks: true 
        });

        ScrollTrigger.scrollerProxy(body_scroller_element, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });

        bodyScrollBar.addListener(ScrollTrigger.update);

        // Only necessary to correct marker position - not needed in production
        if (document.querySelector('.gsap-marker-scroller-start')) {		
            const markers = gsap.utils.toArray('[class *= "gsap-marker"]');	
            bodyScrollBar.addListener(({ offset }) => {  
                gsap.set(markers, { marginTop: -offset.y })
            });
        }
    }

    function loadAllAnimations() {

        // ______________________________
        // gsap commons
        // ''''''''''''''''''''''''''''''

        ScrollTrigger.defaults({
            toggleActions: "restart pause resume none",
            markers: false,
            scroller: '#body-scroller',
            pinType: isTouchDevice ? 'fixed' : 'transform'
        });

        // gsap.config({
        //     force3D: true,
        // });

        // responsive
        let mm = gsap.matchMedia();

        // appear effect
        gsap.utils.toArray(".gsap-appear").forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "resume pause resume none",
                    start: "top 90%",
                },
                opacity: 1,
                duration: 1.5
            });
        });

        // parallax effect
        gsap.utils.toArray( '.gsap-parallax' ).forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: $(item).parent()[0],
                    start: "top bottom",
                    end: "bottom 50%",
                    scrub: true,
                },
                yPercent: 40,
            });
        });

        // parallax effect (min)
        gsap.utils.toArray( '.gsap-parallax-min' ).forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: $(item).parent()[0],
                    start: "top 80%",
                    end: "bottom bottom",
                    scrub: true,
                },
                y: "0",
            });
        });

        // parallax effect (reverse)
        gsap.utils.toArray( '.gsap-parallax-reverse' ).forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: $(item).parent()[0],
                    start: "top bottom",
                    end: "bottom 50%",
                    scrub: true,
                },
                yPercent: -20,
            });
        });

        // parallax effect with scale
        gsap.utils.toArray( '.gsap-parallax-scale' ).forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: $(item).parent()[0],
                    start: "top bottom",
                    end: "bottom 50%",
                    scrub: true,
                },
                yPercent: 40,
                scale: 1.08
            });
        });

        // parallax effect for background images
        gsap.utils.toArray( '.gsap-parallax-bg' ).forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: $(item).parent()[0],
                    start: "top bottom",
                    end: "bottom top",
                    scrub: true,
                },
                top: "0"
            });
        });

        // ______________________________
        // covers
        // ''''''''''''''''''''''''''''''

        [
            'home',
            'video',
            'bottom',
        ]
        .forEach(function(id) {
            gsap.to(`#${id}-cover`, {
                scrollTrigger: {
                    trigger: `#${id}-cover-container`,
                    start: "top top",
                    end: "bottom top",
                    scrub: 1,
                    pin: true,
                    pinSpacing: false
                },
            });
        });

        // ______________________________
        // tres puntos (asesoramos)
        // ''''''''''''''''''''''''''''''

        [
            '#s1bis-punto-1',
            '#s1bis-punto-2',
            '#s1bis-punto-3'
        ]
        .forEach(function(id) {
            gsap.to(id, {
                scrollTrigger: {
                    trigger: () => {
                        if (isMobile) {
                            return id;
                        } else {
                            return '#s1bis';
                        }
                    },
                    start: () => {
                        if (isMobile) {
                            return "top 85%";
                        } else {
                            switch (id) {
                                case '#s1bis-punto-1':
                                    return "top 75%";
                                case '#s1bis-punto-2':
                                    return "top 50%";
                                case '#s1bis-punto-3':
                                    return "top 30%";
                            }
                        }
                    },
                    end: () => {
                        if (isMobile) {
                            return "top 65%"
                        } else {
                            switch (id) {
                                case '#s1bis-punto-1':
                                    return "top 40%";
                                case '#s1bis-punto-2':
                                    return "top 30%";
                                case '#s1bis-punto-3':
                                    return "top 20%";
                            }
                        }
                    },
                    scrub: 2,
                },
                y: 0,
                opacity: 1,
            });
        });

        // ______________________________
        // galleries - MOBILE
        // ''''''''''''''''''''''''''''''

        gsap.utils.toArray( '.gsap-mobilegallerypic' ).forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: $(item).parent()[0],
                    start: "top top",
                    end: "top -100%",
                    pin: true,
                    pinSpacing: false,
                    scrub: true,
                },
                yPercent: -40
            });
        });

        // ______________________________
        // bolso grande
        // ''''''''''''''''''''''''''''''

        gsap.to('#bolso-image', {
            scrollTrigger: {
                trigger: '#bolso-section',
                start: "top 50%",
                end: "top -65%",
                pin: true,
                pinSpacing: false,
            },
        });

        gsap.to('#bolso-text', {
            scrollTrigger: {
                trigger: '#bolso-section-container',
                start: "top top",
                end: "top -20%",
                scrub: 2.5,
            },
            y: () => {
                if (window.innerHeight > 1200) {
                    return "-65%"
                } else {
                    return "-55%"
                }
            },
            opacity: 1
        });

        // ______________________________
        // tres puntos (sostenibilidad)
        // ''''''''''''''''''''''''''''''

        [
            '#sostenibilidad-punto-1',
            '#sostenibilidad-punto-2',
            '#sostenibilidad-punto-3'
        ]
        .forEach(function(id) {
            gsap.to(id, {
                scrollTrigger: {
                    trigger: id,
                    start: "top 85%",
                    end: "top 70%",
                    scrub: 3,
                },
                x: 0,
                opacity: 1
            });
        });

        // MOBILE

        // mm.add("(max-width: 767px", () => {
        //     // ______________________________
        //     // valores lupa - MOBILE
        //     // ''''''''''''''''''''''''''''''
        //     // gsap.utils.toArray(".valor").forEach(item => {
        //     //     let tl = gsap.timeline({
        //     //         scrollTrigger: {
        //     //             trigger: item,
        //     //             start: "top 85%",
        //     //             end: "top 15%",
        //     //             scrub: true,
        //     //         }
        //     //     })
        //     //     .to(item, {
        //     //         scale: 1.15, 
        //     //         left: "5.6vw",
        //     //         ease: "none"})
        //     //     .to(item, {
        //     //         scale: 1, 
        //     //         width: "70vw",
        //     //         left: 0,
        //     //         ease: "none"}, '+=0.5')
        //     // });
        // });

        // DESKTOP

        mm.add("(min-width: 768px)", () => {

            // ______________________________
            // galleries - DESKTOP
            // ''''''''''''''''''''''''''''''

            [
                'gallery1-0','gallery1-2',
                'gallery2-0','gallery2-2'
            ]
            .forEach(function(id) {
                gsap.utils.toArray( '.' + id ).forEach(item => {
                    gsap.to('.' + id, {
                        scrollTrigger: {
                            trigger: '#' + id,
                            start: "top top",
                            end: "bottom top",
                            pin: true,
                            pinSpacing: false,
                            scrub: true,
                            pinType: 'transform'
                        },
                        yPercent: -40
                    });
                });
            });

            // ______________________________
            // especificaciones - foto izquierda - SOLO DESKTOP
            // ''''''''''''''''''''''''''''''

            let tl_specs_image = gsap.timeline({
                scrollTrigger: {
                    trigger: '#specs-trigger',
                    start: "top 50%",
                    end: "top -250%",
                    pin: true,
                    pinSpacing: false,
                    scrub: true,
                    invalidateOnRefresh: true
                },
            })
            .to('#specs-image', {top: "15%", ease: "none", duration: 1})
            .to('#specs-image', {
                top: function() {
                    const top = $('#specs-container').height() - 1.35*$('#specs-image').height();
                    return top;
                }, 
                ease: "none", 
                duration: 1}
                ); 

            // ______________________________
            // valores lupa - DESKTOP
            // ''''''''''''''''''''''''''''''
            // gsap.utils.toArray(".valor__title, .valor__text").forEach(item => {
            //     let tl = gsap.timeline({
            //         scrollTrigger: {
            //             trigger: item,
            //             start: "top 70%",
            //             end: "top 30%",
            //             scrub: 0.7,
            //         }
            //     })
            //     .to(item, {scale: 1.18, ease: "none"})
            //     .to(item, {scale: 1, ease: "none"}, '+=0.5')
            // });

            // ______________________________
            // sostenibilidad - DESKTOP SOLO
            // ''''''''''''''''''''''''''''''

            gsap.timeline({
                scrollTrigger:{
                    trigger: "#sostenibilidad-image",
                    pin: true,
                    pinSpacing: false,
                    start: "top " + ( window.innerHeight - $('#sostenibilidad-image').height() )*0.5,
                    // end: "bottom " + ( window.innerHeight - $('#sostenibilidad-image').height() )*0.5,
                    end:'+='  + (($('#sostenibilidad-container').height() - $('#sostenibilidad-image').height())),
                }
            });
        });

        // ______________________________
        // valores lupa
        // ''''''''''''''''''''''''''''''

        gsap.utils.toArray(".valor").forEach(item => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: item,
                    start: () => {
                        if (isMobile) {
                            return "top bottom"
                        } else {
                            return "top 65%"
                        }
                    },
                    end: () => {
                        if (isMobile) {
                            return "top top"
                        } else {
                            return "top 35%"
                        }
                    },
                    scrub: true,
                },
            })
            .to(item, {opacity: 1, ease: "none", duration: 1})
            .to(item, {opacity: 0.3, ease: "none", duration: 1,});
        });

        // ______________________________
        // especificaciones - derecha 1
        // ''''''''''''''''''''''''''''''

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".spec--1",
                start: "top 30%",
                toggleActions: "resume pause resume none",
            }
        })
        .to('#floating-0', {
            opacity: 1,
            duration: 1.5,
            ease: 'ease-in-out'
        })
        .to('#floating-1', {
            opacity: 1,
            duration: 1.5,
            ease: 'ease-in-out'
        },'-=1')
        .to('#floating-2', {
            opacity: 1,
            duration: 1.5,
            ease: 'ease-in-out'
        },'-=1')

        // ______________________________
        // especificaciones - derecha 2 y 3
        // ''''''''''''''''''''''''''''''

        gsap.utils.toArray(".spec .squareflex, .spec .vignette p, .spec .vignette__rightalt .text, .spec .vignette__rightalt .img, .vignette__right").forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 70%",
                    toggleActions: "resume pause resume none",
                },
                opacity: 1,
                duration: 1.5,
                ease: 'ease-in-out'
            });
        });

        // ______________________________
        // especificaciones - derecha 2 y 3 - lineas
        // ''''''''''''''''''''''''''''''

        gsap.utils.toArray(".js-vignette-line").forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 70%",
                    toggleActions: "resume pause resume none",
                },
                x: 0,
                duration: 1,
                ease: 'ease'
            });
        });

        // ______________________________
        // valores - pin
        // ''''''''''''''''''''''''''''''

        gsap.timeline({
            scrollTrigger:{
                trigger: "#valores-container",
                pin: true,
                pinSpacing: false,
                start: "top top",
                end:'+=' + ($('#valores-section').height() - window.innerHeight),
            }
        });

    };


    // ============================================================================
    // ================================= UTILS ====================================
    // ============================================================================

    // ------------------------------------------------------------
    // IS VISIBLE
    // - Returns if an element is visible
    // - If scroll parent is not window, specify it in 'data-parent'
    // ------------------------------------------------------------

    function isVisible(element) {

        // if (element[0]) {
            
        //     let parent_data = element.data('parent');

        //     if (parent_data) {
        //         let parent = document.querySelector(parent_data);
        //         const { bottom, height, top } = element[0].getBoundingClientRect();
        //         const containerRect = parent.getBoundingClientRect();
            
        //         return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;

        //     } else {
        //         var top_of_element = element.offset().top;
        //         var bottom_of_element = element.offset().top + $(window).outerHeight();
        //         var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
        //         var top_of_screen = $(window).scrollTop();
                
        //         return (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element);
        //     }

        // } else {

        //     return false;
        // }

        if (!isTouchDevice) {
            // in normal screens, we use smooth-scrollbar method
            return bodyScrollBar.isVisible(element[0]);
        } else {
            // in mobile screens we use ScrollTrigger method
            return ScrollTrigger.isInViewport(element[0]);
        }
    }

    // ----------------------------------------
    // INCLUDE WEBP FOR BACKGROUND IMAGES
    // - checks webp compatibility
    // - if compatible leaves current url of bg images as fallback, but add url for webp version
    // - 404 will display if there is now webp
    // ----------------------------------------

    function canUseWebp() {
        let useWebp = false;
        if (typeof document === 'object') {
        // Client side rendering
        const canvas = document.createElement('canvas');
    
        if (
            canvas.getContext &&
            canvas.getContext('2d') &&
            canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0
        ) {
            useWebp = true;
        }
        }
        return useWebp;
    };

    function getExtension(filename) {
        return filename.substring(filename.lastIndexOf(".") + 1);
    }

    function changeToWebP() {
        if (canUseWebp()) {

            $('*').each(function(){
                let el = $(this);
                let oldbg = el.css('background-image');
                if (oldbg != 'none' && oldbg.startsWith('url(')) {

                    let oldurl = /^url\((['"]?)(.*)\1\)$/.exec(oldbg);
                    oldurl = oldurl ? oldurl[2] : ""; 
                    let oldext = getExtension(oldurl);

                    if (oldext == 'jpg' || oldext == 'jpeg' || oldext == 'png' ) {
                        let url = `${oldurl}.webp`;
                        el.css('background-image', `${oldbg}, url("${url}")`);
                    }
                }
            });
        }
    };

    changeToWebP();



    // // ----------------------------------------
    // // vh units workaround
    // // - https://stackoverflow.com/questions/44109314/javascript-calculate-with-viewport-width-height
    // // ----------------------------------------

    // function vh(v) {
    //     var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    //     return (v * h) / 100;
    // }



    // --------------------------------------------------------------------------------
    // UNIVERSAL SCROLL FOR MAGIC SCROLLBAR SITUATIONS
    // - adapts scroll to wether there is magic scrollbar (desktop) or normal scrollbar (mobile)
    // - adapt scroller container and scrollbar name to project
    // --------------------------------------------------------------------------------

    function universalYScroll(opt) {

        const {
            scroll_container = $('#scroll-content'), // project-specific !!
            scroll_bar = bodyScrollBar, // project-specific !!

            y = 0,
            element = false,
            time = 500, 
            behavior = 'smooth',
            callback = ()=>{}, 
    
            iterative = false, // optional iterative scroll 
            iterative_time = 100, // duration of iterative scroll
    
        } = opt;

        $('body').addClass('smooth-scrolling');
        adaptiveScroll(time);

        setTimeout(function() {

            if (iterative) {
                const loop = setInterval(function() {
                    if ( getElementOffset() > 5 ) {
                        adaptiveScroll(iterative_time);
                    } else {
                        clearInterval(loop);
                        $('body').removeClass('smooth-scrolling');
                        callback();
                    }
                }, iterative_time);

            } else {
                $('body').removeClass('smooth-scrolling');
                callback();
            }
        },time);


        function adaptiveScroll(t) {

            // in case of Desktop, we use the, we scroll using "smooth-scrollbar" plugin
            if (!isTouchDevice) {
                scroll_bar.scrollTo( 0, getTargetScroll(), t );

            // in case of Touch device, we scroll using default javascript scroll
            } else {
                scroll_container.animate({
                    scrollTop: getTargetScroll(),
                    behavior: behavior
                }, t);
            }
        }

        function getTargetScroll() {
            return element? getScrolled() + getElementOffset() : y;
        }

        function getElementOffset() {
            return element.offset().top
        }

        function getScrolled() {
            return isTouchDevice ? the_scroller.scrollTop() : scroll_bar.scrollTop;
        }
    }



    // ----------------------------------------
    // ANCHOR HASH SMOOTH
    // - gets location hash and perfroms smooth scroll animation
    // ----------------------------------------

    // arriving by url

    // get hash
    const the_hash = location.hash;

    // prevent default scroll
    window.location.href.split('#')[0]

    function checkLocationHash() { // must be called on window load
        if (the_hash) {
            universalYScroll({
                element: $(the_hash), 
                time: 10
            })
        }
    }

    // links on page
    $('.js-smooth-anchor').click( function(event) {

        event.preventDefault();

        let target_element = $($(this).attr('href'));

        // specific for this project
        // target_element.css('display','block');

        universalYScroll({
            element: target_element, 
            time: 1500, 
            iterative: true, 
            iterative_time: 300, 
        });


    })


    // ------------------------------------------------------------
    // AUTO VIDEO
    // - lazy load, loads on visible
    // - 2 responsive sizes of video
    // - start animation
    // ------------------------------------------------------------

    function checkVideos() { // execute on window load and on scroll 

        $('.js-autovideo').each(function () {

            let container = $(this);

            if (isVisible(container)) {
                loadVideo(container);
            }
        });

        $('.js-autovideo-responsive').each(function () {

            let container = $(this);

            calcScreen();

            if (isVisible(container)) {
                if (isTablet) {
                    loadVideo(container, 'mobile');
                } else {
                    loadVideo(container, 'desktop');
                }
            }

        });

        $('.js-autovideo-loaded').each(function () {

            let container = $(this);

            if (isVisible(container)) {
                if (!container.hasClass('playing')) replayVideo(container);
            } else {
                if (container.hasClass('playing')) stopVideo(container);
            }
        });
    }

    function loadVideo($container, responsive = false) {

        // console.log("loadVideo");

        let $video;

        // locate video
        if (responsive) {
            $video = $container.find("video." + responsive);
            $container.removeClass('js-autovideo-responsive');
            $container.addClass('js-autovideo-loaded');
        } else {
            $video = $container.find("video");
            $container.removeClass('js-autovideo');
            $container.addClass('js-autovideo-loaded');
        }

        $container.addClass('playing');

        var video_src = $video.data('src');
        $video.find('source').attr('src', video_src);
        $video.css('opacity', '0');
        $video.css('transition','opacity 0.5s ease');

        const video = $video[0];

        setTimeout(function () {
            video.load();
            video.play();
            $video.css('opacity', '1');
            // if (isTouchScreen) video.setAttribute("controls","controls");

            // event listeners
            $container.find('.js-autovideo-controls [data-action=audio]').on('click', function(e) {
                console.log('Mute/unmute video audio');
                if ($container.hasClass('sound-on')) {
                    video.muted = true;
                    $container.removeClass('sound-on');
                } else {
                    video.muted = false;
                    $container.addClass('sound-on');
                }   
            });
            $container.find('.js-autovideo-controls [data-action=rewind]').on('click', function(e) {
                console.log('Rewind video audio');
                video.currentTime = 0;
            });
            $container.find('.js-autovideo-controls [data-action=playpause]').on('click', function(e) {
                console.log('Pause/play audio');
                if ($container.hasClass('playing')) {
                    video.pause(); 
                    $container.removeClass('playing');
                } else {
                    video.play(); 
                    $container.addClass('playing');
                }   
            });

        }, 500);

        setTimeout(function () {
            $video.addClass('loaded');
            $container.find("video:not(.loaded)").remove();

            // deleting video causes a bug in ScrollTrigger pinspacing calculation
            // we have to refresh to make it look good again
            ScrollTrigger.refresh();
        }, 100);
    }

    function stopVideo($container) {

        // console.log("stopVideo");

        let $video = $container.find("video.loaded");
        $video[0].pause();
        $container.removeClass('playing');
    }

    function replayVideo($container) {

        // console.log("replayVideo");

        let $video = $container.find("video.loaded");
        $video[0].play();
        $container.addClass('playing');
        $video.css('opacity', '1');

        // if (isMobile) {
        //     $video[0].setAttribute("controls","");
        // }
    }

    // ________________________________
    // LOADING FIRST CONTENTFUL SECTION
    // ''''''''''''''''''''''''''''''''

    $(".js-firstload").one("load", function() {
      }).each(function() {
        if(this.complete) {
            setTimeout(function() {
                revealFirstContentfulSection();
            },100);
        }
    });

    setTimeout(function() {
        revealFirstContentfulSection();
    },1000);

    function revealFirstContentfulSection() {
        $('#home-cover-container').css('opacity',0.6);
    }

    // header

    let headerFixed = true;
    let headerUnfixing = false;

    function checkHeader(scroll) {

        let header = $('header');
        let screenHeight = window.innerHeight;

        // from this scroll, the header will appear (in distances less than that, it won't)
        let headerMinScroll = $('.template-home')[0] ? screenHeight - 200 : 0;

        // the first time the user scrolls in between headerMinScroll and this scroll, the scroll will get fixed for a while
        let headerFixScroll = $('.template-home')[0] ? 2*screenHeight - 5 : 100;

        if (scroll < headerMinScroll ) {
            header.addClass('hidden');
            header.removeClass('visible');
        } else {     
            if (scroll >= headerFixScroll) {
                if (headerFixed) {
                    if (!headerUnfixing) {
                        headerUnfixing = true;
                        setTimeout(() => {
                            headerFixed = false;
                        },1000);
                    }
                } else {
                    header.removeClass('hidden');
                    header.removeClass('visible');
                }
            } else {
                header.addClass('visible');
                header.removeClass('hidden');
            }
        }
    }



    // ============================================================================
    // ============================ ON WINDOW LOAD ================================
    // ============================================================================

    // $(window).on('load', function() {
    $(document).ready(function() {
        // console.log('window on load');
        console.log('document ready');

        // ______________________________
        // Run BEFORE visible
        // ''''''''''''''''''''''''''''''

        checkLocationHash();
        loadAllAnimations();

        setTimeout(()=>{ // give time for aos

            // ______________________________
            // make visible
            // ''''''''''''''''''''''''''''''
            $('#loader-content').addClass('onload-showing');
            $('#loader-content').removeClass('onload-hidden');
            $('#loader-content').addClass('onload-showed');
            $('#loader-content').removeClass('onload-showing');

            // rescroll to top in case user has scrolled
            universalYScroll({
                y: 0, 
                callback: revealSecondContentfulSection,
                behavior: 'auto'
            });

            function revealSecondContentfulSection() {
                $('#home-cover-container').css('opacity',1);
                $('#home-cover-logo').removeClass('hidden');
                $('#home-cover-chevron').css('opacity',1);

                // ______________________________
                // Run AFTER visible
                // ''''''''''''''''''''''''''''''

                checkVideos();


                // ______________________________
                // header
                // ''''''''''''''''''''''''''''''

                if (!isTouchDevice) {
                    checkHeader(bodyScrollBar.scrollTop);
                } else {
                    checkHeader($('#body-scroller').scrollTop());
                }

                // ______________________________
                // map
                // ''''''''''''''''''''''''''''''

                $('#map').removeClass('initial');

                $('.js-activate-map').on('click', (e) => { 
                    $('#map').toggleClass('active');
                });

                $('#map').on('click', (e) => { 
                    $('#map').removeClass('active');
                });

            }



            // ============================================================================
            // ================================ ON SCROLL =================================
            // ============================================================================

            if (!isTouchDevice) {
                bodyScrollBar.addListener(({ offset }) => {  
                    scrollActions(offset.y);  
                });
            } else {
                $("#body-scroller").on('scroll', (e) => {
                    scrollActions($("#body-scroller").scrollTop());  
                })
            }

            let previousScroll = 0;
            let firstScroll = false;

            function scrollActions(scroll) {

                if (!$('body.smooth-scrolling')[0]) {
                    checkVideos();
                    checkHeader(scroll);
                }

                const header = $("header");

                // downscroll code
                if (scroll > previousScroll){
                    header.css('top', '-200px');

                // upscroll code
                } else {
                    header.css('top', '0');                
                    $('#map').removeClass('active');
                }
                previousScroll = scroll;

                // on the first scroll, update gsap
                if (!firstScroll) {
                    ScrollTrigger.refresh();
                    firstScroll = true;
                }
            }


            // ============================================================================
            // =========================== ON WINDOW RESIZE ===============================
            // ============================================================================

            $(window).on('resize', function(){
                calcScreen();
                checkVideos();
            });
            $(window).on('orientationchange', function(){
                calcScreen();
                checkVideos();
            });

        }, 1000);

    });

}); })();
